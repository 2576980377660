/** @format */

import {
    DocumentDuplicateIcon,
    InformationCircleIcon,
    UserCircleIcon,
} from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import Settings from "../../../components/settings";
import { FaDiscord } from "react-icons/fa";
import NavBar from "../../../components/navBar";
import { useNotification } from "../../../utils/notification/notificationContext";
import { useEffect, useState } from "react";
import { AccountData } from "../../../models/account";
import {
    fetchAccountData,
    fetchStripePortal,
    renewSubscription,
} from "./api/accountApi";
import Cookies from "js-cookie";
import { clearAuthData } from "../../../utils/auth/authUtils";
import { checkAuth } from "../../../utils/auth/verifyAuthToken";
import StatusTags from "../../../components/statusTags";
import { capitalizeFirstLetter } from "../../../utils/strings/capitalize";
import LoadingWheel from "../../../components/loadingWheel";
import { fetchUpgradePaymentLink } from "./api/membershipApi";
import {
    LinkAccountBanner,
    UpcomingCancellationBanner,
} from "../../../components/statusBanners";
import EditSubPopup from "./manage/editSubPopup";

function DashboardPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { showNotification } = useNotification();

    const [accountData, setAccountData] = useState<AccountData | null>(null);
    const [loading, setLoading] = useState(true);

    const [upgradeLoading, setUpgradeLoading] = useState(false);

    const [refetchData, setRefetchData] = useState(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    const fetchData = async () => {
        setLoading(true);
        const data = await fetchAccountData();
        if (data.status === "success") {
            setAccountData(data.data);
            if (data.data.status === "cancelled") {
                Cookies.remove("jwt");
                clearAuthData();
                navigate("/login?membership_status=cancelled");
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [refetchData]);

    const verifyAuth = async () => {
        const authData = await checkAuth();
        if (authData.authenticated) {
            if (authData.clientType === "client") {
                navigate("/");
            } else if (authData.clientType === "admin") {
                navigate("/admin/login");
            }
        } else {
            Cookies.remove("jwt");
            clearAuthData();
            showNotification(
                "Invalid authorization token",
                "Login using your license key",
                "error"
            );
        }
    };

    const handleUpgrade = async () => {
        setUpgradeLoading(true);

        if (!accountData?.username) {
            showNotification(
                "Link your discord account before upgrading",
                "",
                "error"
            );
            setUpgradeLoading(false);
            return;
        }

        const data = await fetchUpgradePaymentLink();
        if (data.status === "success") {
            window.location.href = data.data.url;
        } else {
            showNotification(
                "Failed to generate upgrade link",
                data.message,
                "error"
            );
        }
        setUpgradeLoading(false);
    };

    const handleLinkAccount = () => {
        window.location.href = `${
            process.env.REACT_APP_API_BASE_URL
        }/api/v1/auth/discord/link?token=${Cookies.get("jwt")}`;
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const status = queryParams.get("status");
        if (status === "success") {
            const new_token = queryParams.get("refresh_jwt_token");
            if (new_token) {
                Cookies.set("jwt", new_token, {
                    expires: 30,
                    secure: true,
                });
                verifyAuth();
                // fetchData();
                setLoading(true);
                setTimeout(() => {
                    fetchData();
                }, 1500);
            }
        } else if (status === "failed") {
            showNotification(
                "Failed to link account",
                "Account already exists",
                "error"
            );
        }
    }, []);

    return (
        <div className="flex flex-col ">
            <NavBar />

            {!loading ? (
                <div className="flex flex-col mx-4 sm:mx-16 md:mx-32 lg:mx-44 xl:mx-64 pt-6">
                    {!accountData?.discord_id && (
                        <div className="pb-4">
                            <LinkAccountBanner />
                        </div>
                    )}
                    {accountData?.status === "upcoming_cancellation" ? (
                        <UpcomingCancellationBanner
                            date={accountData?.renews_at || ""}
                            handleRefetch={handleRefetch}
                        />
                    ) : null}
                    <h1 className="font-semibold text-2xl text-left items-start pt-5">
                        Membership
                    </h1>
                    <div className="flex flex-row justify-between w-full pt-3 items-center">
                        <div className="flex flex-row space-x-3 items-center">
                            <span className="bg-lightGray px-5 text-darkGray font-medium text-md rounded-md py-1 items-center justify-center flex">
                                {accountData?.license_key}
                            </span>
                            <span className="bg-lightGray p-1.5 text-darkGray rounded-md border-transparent border hover:border hover:border-gray-300 hover:bg-gray-200">
                                <DocumentDuplicateIcon className="h-5 w-5" />
                            </span>
                        </div>
                        <div>
                            <StatusTags
                                status={accountData?.status || "active"}
                            />
                        </div>
                    </div>

                    <div className="flex flex-row items-center justify-between w-full pt-14">
                        <div className="flex flex-row space-x-20">
                            <div className="flex flex-col">
                                <span>Plan</span>
                                <span>
                                    {capitalizeFirstLetter(
                                        accountData?.plan || ""
                                    )}
                                </span>
                            </div>
                            <div className="flex flex-col">
                                <span>Renews</span>
                                <span>
                                    {accountData?.renews_at
                                        ? accountData?.renews_at
                                        : "Free"}
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-row items-center space-x-4">
                            <div className="flex flex-col text-right">
                                <span>{accountData?.username}</span>
                                <span>{accountData?.email}</span>
                            </div>
                            {accountData?.discord_id ? (
                                <span className="h-10 w-10 bg-discordBlue rounded-lg items-center flex justify-center">
                                    <FaDiscord className="w-6 h-6 text-white" />
                                </span>
                            ) : (
                                <button
                                    onClick={() => handleLinkAccount()}
                                    className="h-10 space-x-3 gap-3 bg-discordBlue px-2.5 rounded-lg items-center flex justify-center text-white font-medium hover:bg-discordBlueHighlight"
                                >
                                    <FaDiscord className="w-6 h-6 text-white" />
                                    Connect your account
                                </button>
                            )}
                        </div>
                    </div>

                    <div className="pt-16"></div>
                    <h1 className="font-semibold text-2xl text-left items-start">
                        Manage
                    </h1>

                    <div className="flex flex-row pt-4">
                        <EditSubPopup
                            status={accountData?.status || "active"}
                            accountData={accountData}
                            handleRefetch={handleRefetch}
                        />
                        {accountData?.plan === "free" && (
                            <button
                                disabled={upgradeLoading}
                                onClick={() => handleUpgrade()}
                                className="text-left px-3 py-1.5 rounded-md items-start bg-accent text-white font-medium hover:bg-accenthighlight"
                            >
                                {upgradeLoading && (
                                    <span className="pr-2">
                                        <LoadingWheel color="white" />
                                    </span>
                                )}
                                Upgrade membership
                            </button>
                        )}
                    </div>
                </div>
            ) : (
                <div className="flex flex-row items-center justify-center pt-44">
                    <LoadingWheel />
                </div>
            )}
        </div>
    );
}

export default DashboardPage;
